import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import db from './db.json';

function App() {
  const [filtered, setFiltered] = useState(null);
  const [pick, setPick] = useState(null);

  const [isLoading, setLoading] = useState(true);

  const Generate = () => {
    setLoading(true);
    setPick(db[Math.floor(Math.random() * db.length)]);
  };

  useEffect(() => {
    const f = db.filter(({ word }) => word.endsWith('en') || word.endsWith('an'));
    const w = f[(Math.floor(Math.random() * f.length))].word;

    if (w.slice(-3) === 'len') {
      setFiltered(`${w}dir`);
    } else if (w.slice(-3) === 'lan') {
      setFiltered(`${w}dır`);
    } else if (w.slice(-2) === 'en') {
      setFiltered(`${w}lendir`);
    } else {
      setFiltered(`${w}landır`);
    }

    Generate();
  }, []);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setLoading(false);
      }, 500 + Math.floor(Math.random() * 1000));
    }
  }, [isLoading]);

  return (
    <div className="abs-center d-flex flex-column">
      {pick && (
        isLoading ? (
          <div className="d-flex justify-content-center scale-2 mb-4">
            <div className="spinner-border" role="status" />
          </div>
        ) : (
          <>
            <h1 className="display-1 text-center">{pick.word.toLocaleUpperCase()}</h1>
            <blockquote className="blockquote">
              <p className="mb-0 text-center">{pick.meaning}</p>
            </blockquote>

            <hr />

            <blockquote className="blockquote">
              <p className="mb-4 text-center"><i>{pick.example.split('-')[0]}</i></p>
              <footer className="blockquote-footer text-center">
                <cite title="Source Title">{pick.example.split('-')[1]}</cite>
              </footer>
            </blockquote>
          </>
        )
      )}

      {filtered && (
        <div className="d-flex justify-content-center mt-4">
          <button className="btn btn-primary" type="button" onClick={Generate}>
            <b>
              {capitalize(filtered)}
            </b>
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
